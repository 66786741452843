<template>
  <section id="resources">
    <v-container fluid>
      <base-info-card title="FirstVault Resources" pace="6"></base-info-card>
      <v-row>
        <v-col
          v-for="item in blogsDescription"
          :key="item.id"
          cols="12"
          sm="12"
          md="3"
        >
          <v-card>
            <v-img :src="item.imgSource"></v-img>
            <v-card-title class="text-uppercase my-card-text-header">
              <span class="font-weight-light">{{ item.titleLight }}</span>
              <span class="font-weight-bold">{{ item.titleBold }}</span>
            </v-card-title>
            <v-card-text>
              <span class="my-font-card-text">
                {{ item.briefDescription }}
              </span>
            </v-card-text>
            <v-card-actions class="my-card-actions">
              <v-btn text :to="item.toBlog"> View Full Article </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "SectionResources",
  data() {
    return {
      blogsDescription: [
        {
          id: 1,
          toBlog: "/blogs/need-for-data-security",
          titleLight: "Need For",
          titleBold: " Data Security",
          imgSource: require("@/components/Blogs/Blog1_files/imgFeatured.png"),
          briefDescription:
            "Data security is a journey; it's no simple walk in the park. You cannt control a hacker from hacking your network, but you" +
            " can take necessary steps to protect your data. What are the leading reasons for organizations to suffer from data breaches?" +
            " According to PwC, insider negligence, malicious or criminal attacks, IT system errors, and third-party...",
        },
        {
          id: 2,
          toBlog: "/blogs/ways-to-prevent-data-breach",
          titleLight: "Ways To Prevent ",
          titleBold: "Data Breach",
          imgSource: require("@/components/Blogs/Blog2_files/imgFeatured.png"),
          briefDescription:
            "Do you know how hackers are hollowing out our needy information like termites through Data breaches? In today's time where" +
            " the world is expanding rapidly in technology, the techniques to steal the data of people's needs are not far behind. Not only" +
            " big businesses but also Individuals are also not able to escape from being a victim...",
        },
        {
          id: 3,
          toBlog: "/blogs/hackers-targeting-individuals",
          titleLight: "Hackers Targeting ",
          titleBold: "Individuals",
          imgSource: require("@/components/Blogs/Blog3_files/imgFeatured.png"),
          briefDescription:
            "It is a matter of concern how hackers are building their game day by day. On an average, one cyber-attack is done every hour" +
            " in the world, and its threat is looming not only on the businesses but also on the individuals who have personal data." +
            " But how do they reach out to such people? Well, Hackers are near to anybody who has such...",
        },
        {
          id: 4,
          toBlog: "/blogs/corporations-targeted",
          titleLight: "Corporations ",
          titleBold: "Targeted",
          imgSource: require("@/components/Blogs/Blog4_files/imgFeatured.png"),
          briefDescription:
            "Talking about cyber threats, some people think whether hackers can target any organization, given they (organizations) have" +
            " a systematic plan for everything. The answer is a definitive yes. To a substantial extent, even big organizations are trying" +
            " hard to protect themselves from cyber-attacks. Some smart companies spend a good amount to...",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.my-font-card-text {
  font-family: "Roboto", sans-serif;
  color: #0c2340;
  align-content: left;
}
.my-card-text-header {
  font-family: "Roboto", sans-serif;
  color: #0c2340;
  align-content: left;
}
.my-card-actions {
  font-family: "Roboto", sans-serif;
  color: #0c2340;
  background-color: #f5f5f5;
  align-content: left;
}
</style>